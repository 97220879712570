/* Specifika stilar för reviews-page för att undvika konflikter med hemsidan */
body:not(.reviews-page) .review-modal-overlay,
html:not(.reviews-page) .review-modal-overlay,
.home-page .review-modal-overlay,
#root:not(.reviews-page) .review-modal-overlay,
div:not(.reviews-page) > .review-modal-overlay {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  pointer-events: none !important;
  z-index: -9999 !important;
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
}

.reviews-page {
  padding-top: 0;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.reviews-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.reviews-page .reviews-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.reviews-page .reviews-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

/* Formulär-specifika stilar */
.reviews-page .review-form {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px !important;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Input och textarea stilar */
.review-input,
input[type="text"],
textarea,
select {
  width: 100% !important;
  box-sizing: border-box !important;
  font-size: 16px !important;
  padding: 12px !important;
  max-width: 100% !important;
  overflow: visible !important;
  text-overflow: clip !important;
  white-space: normal !important;
  margin-bottom: 15px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
}

/* Förbättra textområden för recensioner */
textarea.review-input {
  min-height: 100px !important;
  height: auto !important;
  resize: vertical !important;
}

/* Återställ namnstorlekar till normalt */
.review-name,
.reviewer-name,
.review-header .name,
.review-card .name {
  font-size: 16px !important; /* Återställ till normal storlek */
}

/* Alternativt, om du vill tillåta radbrytning efter ord men inte inom namn */
.review-name,
.reviewer-name {
  overflow-wrap: normal !important; /* Använd normal wrapping */
  word-break: keep-all !important; /* Håll ihop ord */
  hyphens: none !important; /* Förhindra avstavning */
}

/* Moderna recensionskort med CSS Grid */
.reviews-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  isolation: isolate;
  grid-auto-rows: auto;
  align-items: start;
}

/* Force strict isolation between grid items */
.reviews-grid > * {
  overflow: visible;
  z-index: auto;
  contain: layout style;
  grid-row-end: span 1;
  align-self: start;
  min-width: 0;
}

/* Recensionskort med grid-row satt till auto för att förhindra påverkan på andra kort */
.review-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.3s ease;
  isolation: isolate;
  grid-row: auto !important;
  grid-column: auto !important;
  height: auto;
  min-height: min-content;
  overflow: hidden;
  contain: layout;
}

/* Förhindra spridning av expanderade recensioner till grannrecensioner */
.review-card.expanded {
  z-index: 10 !important;
  position: relative !important;
  height: auto !important;
  align-self: start !important;
  overflow: visible !important;
  display: flex !important;
  flex-direction: column !important;
  transform: translateY(-3px) !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15) !important;
}

/* Förhindra att expanderad text påverkar layouten för andra kort */
.review-card .review-comment {
  contain: content;
}

/* Recensionskommentarer med explicita höjder */
.review-card:not(.expanded) {
  height: auto !important;
  align-self: start !important;
  overflow: hidden !important;
  position: relative !important;
  transform: none !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
}

/* Recensionskommentarer */
.review-comment {
  width: 100%;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-line;
  line-height: 1.5;
  color: #333;
  font-size: 14px;
  padding: 0;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}

/* Förbättrad kommentarvisning */
.review-card.expanded .review-comment {
  max-height: none !important;
  height: auto !important;
  overflow: visible !important;
  white-space: pre-line !important;
  word-break: break-word !important;
  display: block !important;
  line-height: 1.6 !important;
  width: 100% !important;
  font-size: 14px !important;
  padding: 15px !important;
  contain: none !important;
}

/* Ta bort max-height för recensionskommentarerna */
.review-comment.expanded,
.review-comment[style*="none"],
.review-comment[style*="visible"] {
  -webkit-line-clamp: unset !important;
  -webkit-box-orient: initial !important;
  display: block !important;
  text-overflow: initial !important;
}

/* Ta bort webkit line-clamp */
.review-comment:not(.expanded) {
  -webkit-line-clamp: initial !important;
  -webkit-box-orient: initial !important;
  display: block !important;
}

/* Bildcontainer */
.review-image-container {
  width: 100%;
  max-height: 180px;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 10px;
  position: relative;
  transition: max-height 0.3s ease;
}

/* Expanderad bildcontainer */
.review-card.expanded .review-image-container {
  max-height: 300px;
}

/* "Visa mer"-knapp */
.view-full-review-button button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  transition: background-color 0.2s;
}

.view-full-review-button button:hover {
  background-color: #000;
}

/* Responsiv design för olika skärmstorlekar */
@media (max-width: 992px) {
  .reviews-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .reviews-grid {
    grid-template-columns: minmax(0, 1fr);
    gap: 15px;
  }
}

/* Stilar för review header - centrerad layout */
.review-header {
  display: flex !important;
  flex-direction: column !important; /* Ändra till kolumnlayout */
  justify-content: center !important;
  align-items: center !important; /* Centrera vågrätt */
  width: 100% !important;
  padding-bottom: 10px !important;
  margin-bottom: 10px !important;
  border-bottom: 1px solid #eee !important;
  text-align: center !important;
}

/* Stilar för review namn - centrerat med konsekvent storlek på alla enheter */
.review-name {
  width: 100% !important;
  max-width: 100% !important;
  color: #333 !important;
  font-weight: bold !important;
  font-size: 16px !important; /* Konsekvent storlek */
  text-align: center !important;
  margin-bottom: 5px !important;
  white-space: normal !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

/* Stilar för review rating (stjärnor) - centrerade */
.review-rating {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important; /* Centrera stjärnor */
  text-align: center !important;
  margin: 0 auto !important; /* Centrera block */
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  position: static !important;
  left: auto !important;
}

/* Stilar för stjärnorna - liknande bilden */
#root .review-rating span,
.reviews-page .review-rating span,
.reviews-page [style*="★"],
.reviews-page span[style*="color: #ffd700"],
.reviews-page .review-card span[style*="color: #ffd700"],
div[class*="review"] span[style*="color"],
div[class*="review"] .review-rating *,
.reviews-page div span[style*="color"],
span.review-stars,
.review-rating span {
  font-size: 25px !important;
  line-height: 1 !important;
  letter-spacing: -1px !important;
}

/* Stilar för review thumbnail - liknande bilden */
.review-thumbnail {
  width: 100% !important;
  height: auto !important;
  object-fit: contain !important;
  border-radius: 4px !important;
}

/* Datum - liknande bilden */
.review-date {
  margin-top: 10px !important;
  font-size: 14px !important;
  color: #777 !important;
  text-align: left !important; /* Datum till vänster istället för höger */
}

/* Förbättra layouten för mobil generellt */
@media (max-width: 768px) {
  .reviews-container {
    padding: 10px !important;
  }

  .review-form {
    padding: 15px !important;
  }

  /* Enklare header på mobil */
  .review-header {
    flex-wrap: nowrap !important; /* Förhindra wrapping av header-innehåll */
    overflow: hidden !important;
  }

  /* Säkerställ att recensionsgrid är en kolumn */
  .reviews-grid {
    display: block !important;
    width: 100% !important;
  }

  /* Förstora popup för bättre läsbarhet */
  .popup-comment,
  .review-modal {
    width: 90% !important;
    height: auto !important;
    max-height: 80vh !important;
    padding: 20px !important;
    padding-top: 45px !important;
    font-size: 16px !important;
  }

  /* Säkerställ att text är synlig */
  .popup-text {
    font-size: 16px !important;
    line-height: 1.6 !important;
    word-break: break-word !important;
  }

  .review-name {
    font-size: 16px !important; /* Samma storlek som desktop */
  }
}

/* Extra justeringar för riktigt små skärmar */
@media (max-width: 480px) {
  /* Öka storleken på alla inputs för bättre touchvänlighet */
  .review-input,
  input[type="text"],
  textarea,
  select {
    padding: 15px !important;
    margin-bottom: 15px !important;
    font-size: 16px !important;
  }

  .submit-button {
    padding: 15px !important;
    font-size: 16px !important;
    height: auto !important;
  }

  /* Förbättra flödet för långa namn */
  .review-header {
    padding-bottom: 10px !important;
    border-bottom: 1px solid #eee !important;
    margin-bottom: 10px !important;
  }

  .review-name {
    font-size: 16px !important; /* Samma storlek som desktop */
  }
}

.submit-button {
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
}

.loading {
  text-align: center;
  padding: 20px;
  color: #666;
}

.no-reviews {
  text-align: center;
  padding: 20px;
  color: #666;
  grid-column: 1 / -1;
}

.review-comment.expanded {
  max-height: 1000px !important;
  font-size: 16px !important;
  margin-bottom: 16px !important;
}

.review-comment-container {
  margin-bottom: 10px !important;
}

/* Rensa bort äldre överskrivande CSS för knapparna */
.read-more-button,
.read-more-button-new,
.explicit-read-more-button button {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  pointer-events: none !important;
}

/* Säkerställ att popup-modalerna alltid visas överst */
.reviews-page.review-modal-overlay,
body.modal-open.reviews-page .review-modal-overlay {
  z-index: 2147483647 !important; /* Maxvärdet för z-index */
  position: fixed !important;
}

/* Garantera att stängningsknappen alltid fungerar */
.close-modal,
.reviews-page .close-modal {
  z-index: 2147483647 !important; /* Maxvärdet för z-index */
  cursor: pointer !important;
  pointer-events: auto !important;
  visibility: visible !important;
  opacity: 1 !important;
}

.expand-icon,
.collapse-icon {
  font-size: 0.8rem;
  margin-left: 3px;
}

.truncated-text p {
  margin-bottom: 5px;
}

.expanded-text {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}

.review-comment:not(.expanded) {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.review-comment.expanded {
  position: relative !important;
  z-index: 10 !important;
  background-color: white !important;
  padding: 15px !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
  margin: 10px 0 !important;
  max-height: none;
  animation: expandComment 0.3s ease-out forwards;
  transform-origin: top;
}

@keyframes expandComment {
  0% {
    opacity: 0.7;
    transform: scaleY(0.8);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

/* För att säkerställa att all text är läsbar */
.review-card p,
.review-card div,
.review-card span {
  color: #333;
}

/* Responsiv design för alla enheter med centrerad layout */
@media (max-width: 1200px) {
  .reviews-grid {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 20px !important;
  }

  .review-name {
    font-size: 16px !important;
  }

  #root .review-rating span,
  .reviews-page .review-rating span,
  .reviews-page [style*="★"],
  .reviews-page span[style*="color: #ffd700"],
  .reviews-page .review-card span[style*="color: #ffd700"] {
    font-size: 22px !important;
  }
}

@media (max-width: 768px) {
  .reviews-grid {
    grid-template-columns: 1fr !important;
    gap: 15px !important;
  }

  .review-card {
    padding: 15px !important;
  }

  .review-name {
    font-size: 16px !important;
  }

  #root .review-rating span,
  .reviews-page .review-rating span,
  .reviews-page [style*="★"],
  .reviews-page span[style*="color: #ffd700"],
  .reviews-page .review-card span[style*="color: #ffd700"] {
    font-size: 22px !important;
  }

  .view-full-review-button button {
    width: 100% !important;
    padding: 8px !important;
    font-size: 14px !important;
    text-align: center !important;
  }
}

@media (max-width: 480px) {
  .reviews-container {
    padding: 0 10px !important;
  }

  .review-card {
    padding: 12px !important;
    margin-bottom: 15px !important;
  }

  .review-header {
    padding-bottom: 8px !important;
    margin-bottom: 8px !important;
  }

  .review-name {
    font-size: 16px !important; /* Samma storlek som desktop */
  }

  #root .review-rating span,
  .reviews-page .review-rating span,
  .reviews-page [style*="★"],
  .reviews-page span[style*="color: #ffd700"],
  .reviews-page .review-card span[style*="color: #ffd700"] {
    font-size: 20px !important;
  }

  .review-comment {
    font-size: 14px !important;
    line-height: 1.4 !important;
    max-height: 60px !important;
  }

  .review-date {
    margin-top: 8px !important;
    font-size: 12px !important;
  }
}

@media (max-width: 375px) {
  .review-card {
    padding: 10px !important;
  }

  .review-name {
    font-size: 16px !important; /* Samma storlek som desktop */
  }

  #root .review-rating span,
  .reviews-page .review-rating span,
  .reviews-page [style*="★"],
  .reviews-page span[style*="color: #ffd700"],
  .reviews-page .review-card span[style*="color: #ffd700"] {
    font-size: 18px !important;
  }

  .review-comment {
    font-size: 13px !important;
    line-height: 1.3 !important;
    max-height: 50px !important;
  }

  .review-date {
    font-size: 11px !important;
  }

  .view-full-review-button button {
    font-size: 12px !important;
    padding: 6px !important;
  }
}

/* Modal-stilar - förbättrad popup-ruta */
.review-modal-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 2147483647 !important;
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box !important;
}

.review-modal {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: white !important;
  border-radius: 12px !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
  padding: 25px !important;
  width: 90% !important;
  max-width: 550px !important;
  max-height: 85vh !important;
  overflow: auto !important;
  z-index: 2147483646 !important;
  display: flex !important;
  flex-direction: column !important;
}

/* Ta bort eventuella bakgrunder som kan orsaka problem */
body.modal-open {
  overflow: hidden !important;
}

/* Förbättrad bildhantering i recensionskort */
.review-image-container {
  margin: 5px 0 10px 0 !important;
  flex-grow: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  max-height: 180px !important;
  overflow: hidden !important;
  border-radius: 4px !important;
  position: relative !important;
  transition: max-height 0.3s ease-in-out !important;
}

.review-image-container.expanded {
  max-height: 400px !important;
  margin: 15px 0 20px 0 !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.review-thumbnail {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  transition: transform 0.2s ease !important;
}

.review-thumbnail:hover {
  transform: scale(1.02) !important;
}

/* Navigeringsknappar för bildgalleri */
.image-nav {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: white !important;
  border: none !important;
  width: 36px !important;
  height: 36px !important;
  border-radius: 50% !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  z-index: 5 !important;
  opacity: 0.7 !important;
  transition: opacity 0.2s ease, background-color 0.2s ease !important;
}

.image-nav:hover {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.prev-image {
  left: 10px !important;
}

.next-image {
  right: 10px !important;
}

/* Bildräknare */
.image-counter {
  position: absolute !important;
  bottom: 10px !important;
  right: 10px !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: white !important;
  padding: 4px 8px !important;
  border-radius: 12px !important;
  font-size: 12px !important;
  z-index: 5 !important;
}

.review-comment-container {
  margin-bottom: 5px !important;
}

/* Header med namn och betyg - fast position högst upp */
.modal-review-header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
  margin-bottom: 20px !important;
  padding-bottom: 15px !important;
  border-bottom: 2px solid #f1f1f1 !important;
  position: sticky !important;
  top: 0 !important;
  background-color: white !important;
  z-index: 5 !important;
  width: 100% !important;
  padding-right: 40px !important; /* Ger plats för stängningsknappen */
}

.modal-review-name {
  font-weight: 700 !important;
  font-size: 22px !important;
  color: #333 !important;
  flex: 1 !important;
}

.modal-review-rating {
  font-size: 22px !important;
  color: #ffd700 !important;
  margin-left: 15px !important;
  display: flex !important;
  align-items: center !important;
}

/* Recensionstext - förbättrad läsbarhet */
.modal-review-comment {
  color: #444 !important;
  line-height: 1.7 !important;
  margin: 15px 0 !important;
  padding: 15px 0 !important;
  font-size: 16px !important;
  white-space: pre-line !important;
  overflow-wrap: break-word !important;
  border-bottom: 1px solid #f1f1f1 !important;
}

/* Förbättrad bildvisning med scrollning */
.modal-review-images {
  display: flex !important;
  flex-direction: column !important;
  gap: 15px !important;
  margin: 15px 0 !important;
  max-height: 50vh !important;
  overflow-y: auto !important;
  padding-right: 10px !important;
}

.modal-image-container {
  width: 100% !important;
  overflow: hidden !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
  transition: transform 0.3s !important;
}

.modal-image-container:hover {
  transform: translateY(-2px) !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15) !important;
}

.modal-image-container img {
  width: 100% !important;
  height: auto !important;
  object-fit: contain !important;
  display: block !important;
  border-radius: 8px !important;
  transition: transform 0.3s !important;
}

/* Datum/footer fast längst ner */
.modal-review-date {
  color: #888 !important;
  font-size: 14px !important;
  text-align: right !important;
  margin-top: 15px !important;
  padding-top: 15px !important;
  position: sticky !important;
  bottom: 0 !important;
  background-color: white !important;
  z-index: 5 !important;
}

/* Säkerställ att andra element inte blockerar modalen */
#root,
.App,
.reviews-page,
.reviews-container,
.review-card {
  position: relative;
  z-index: auto !important;
}

/* Stilar för popup-modaler - se till att de är centrerade */
.review-modal-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 2147483647 !important;
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box !important;
}

.review-modal {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: white !important;
  border-radius: 12px !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
  padding: 25px !important;
  width: 90% !important;
  max-width: 550px !important;
  max-height: 85vh !important;
  overflow: auto !important;
  z-index: 2147483646 !important;
  display: flex !important;
  flex-direction: column !important;
}

/* Stilar för modaler som skapas dynamiskt med JavaScript */
body.modal-open {
  overflow: hidden !important;
}

/* Överskrid alla andra positioner */
[id^="closeBtn-"],
.close-modal {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  z-index: 9999999 !important;
}

/* Absolut centrering för modaler - detta kommer att överrida alla andra stilar */
.force-centered-modal-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  background-color: transparent !important; /* Transparent bakgrund */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  z-index: 9999999999 !important;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  overflow: hidden !important;
}

.force-centered-modal {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: white !important;
  border-radius: 12px !important;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.5) !important;
  width: 90% !important;
  max-width: 400px !important; /* Minska max-bredden för att göra den mer kompakt */
  max-height: auto !important; /* Ta bort begränsning av höjd */
  overflow: visible !important; /* Ta bort scrollning */
  z-index: 9999999999 !important;
  padding: 25px !important;
  margin: 0 !important;
}

/* Reset all body styles when modal is open to prevent interference */
body.force-modal-open {
  overflow: hidden !important;
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Ny, enkel popup-implementation som garanterat fungerar */
.simple-popup-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 9999999999 !important;
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ) !important; /* Mörk, halvt genomskinlig bakgrund */
}

.simple-popup-content {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: white !important;
  padding: 20px !important;
  border-radius: 10px !important;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3) !important;
  max-width: 400px !important;
  width: 90% !important;
  z-index: 9999999999 !important;
  max-height: none !important; /* Ingen maxhöjd */
  overflow: visible !important; /* Ingen scrollning */
}

.simple-popup-header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
  margin-bottom: 15px !important;
  border-bottom: 1px solid #eee !important;
  padding-bottom: 10px !important;
}

.simple-popup-title {
  margin: 0 !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

.simple-popup-close {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  background: #ff4757 !important;
  color: white !important;
  border: none !important;
  width: 24px !important;
  height: 24px !important;
  border-radius: 50% !important;
  font-size: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  line-height: 1 !important;
  padding: 0 !important;
}

.simple-popup-content img {
  max-width: 100% !important;
  max-height: 200px !important;
  object-fit: contain !important;
  margin: 10px 0 !important;
  display: block !important;
}

.simple-popup-footer {
  text-align: right !important;
  color: #888 !important;
  font-size: 12px !important;
  margin-top: 15px !important;
}

/* Enkel, robust popup som garanterat fungerar */
.basic-popup-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 2147483647 !important; /* Maximalt möjliga z-index */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  backdrop-filter: blur(5px) !important;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 1 !important;
  visibility: visible !important;
  pointer-events: auto !important;
  transform: none !important;
  transition: none !important;
  box-sizing: border-box !important;
}

.basic-popup-content {
  background-color: white !important;
  border-radius: 8px !important;
  padding: 20px !important;
  width: 90% !important;
  max-width: 500px !important;
  max-height: 80vh !important;
  overflow-y: auto !important;
  position: relative !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3) !important;
}

.basic-popup-close {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  background-color: #ff4757 !important;
  color: white !important;
  border: none !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  line-height: 1 !important;
  cursor: pointer !important;
}

.basic-popup-title {
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  padding-right: 30px !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

.basic-popup-rating {
  color: gold !important;
  font-size: 16px !important;
  margin-bottom: 10px !important;
}

.basic-popup-image {
  width: 100% !important;
  max-height: 300px !important;
  object-fit: contain !important;
  margin: 10px 0 !important;
  display: block !important;
}

.basic-popup-text {
  margin: 15px 0 !important;
  line-height: 1.5 !important;
  white-space: pre-line !important;
}

.basic-popup-footer {
  text-align: right !important;
  color: #777 !important;
  font-size: 14px !important;
  margin-top: 15px !important;
}

.debug-info {
  margin: 10px 0 20px 0 !important;
  color: #666 !important;
  font-size: 14px !important;
}

/* Förbättrad fullskärmsvy för bilder */
.fullscreen-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fullscreen-image-container {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-image {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
  animation: scale-in 0.3s ease-out;
}

@keyframes scale-in {
  from {
    transform: scale(0.9);
    opacity: 0.5;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.close-fullscreen-button {
  position: absolute;
  top: -40px;
  right: 0;
  background-color: rgba(255, 71, 87, 0.8);
  color: white;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.close-fullscreen-button:hover {
  background-color: rgb(255, 71, 87);
  transform: scale(1.1);
}

/* Göra bilderna klickbara med tydlig hover-effekt */
.review-thumbnail {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.review-thumbnail:hover {
  transform: scale(1.03);
}

/* Ta bort alla stjärn-relaterade stilar som kan orsaka konflikter */
.stars-container,
.star,
.star.filled,
.star.empty {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
}

/* Fixa stjärnstorlek med CSS override */
.review-rating span,
.review-rating span *,
[class*="review"] span[style*="color"],
.review-card .review-rating span {
  font-size: 10px !important;
  line-height: 1 !important;
  display: inline-block !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

@media (max-width: 768px) {
  .review-rating span,
  .review-rating span *,
  [class*="review"] span[style*="color"],
  .review-card .review-rating span {
    font-size: 10px !important;
  }
}

@media (max-width: 480px) {
  .review-rating span,
  .review-rating span *,
  [class*="review"] span[style*="color"],
  .review-card .review-rating span {
    font-size: 5px !important;
  }
}

/* Extremt specifik regel för att tvinga stjärnstorlek på recensionssidan */
.review-rating span,
.review-rating span *,
[class*="review"] span,
[style*="ffd700"] span,
span[style*="color"],
[style*="★"],
.reviews-page span[style*="color"],
span[style*="#ffd700"],
.review-card span[style*="color"],
.review-header span[style*="color"] {
  font-size: 10px !important;
  line-height: 1 !important;
  display: inline-block !important;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  user-select: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  text-shadow: none !important;
}

/* Extremt specifika regler för att säkerställa att innehållet i renderStarsHTML visas korrekt */
span[style*="color: #ffd700"],
span[style*="color:#ffd700"],
[style*="color: gold"],
[style*="color:gold"] {
  font-size: 10px !important;
  letter-spacing: -2px !important;
}

/* Överskrid alla andra stjärnrelaterade stilar */
.star,
.stars,
.rating-stars,
[class*="star"],
.filled-star,
.empty-star {
  font-size: 18px !important;
}

/* Mobilspecifika regler för stjärnorna */
@media (max-width: 768px) {
  .review-stars,
  span[style*="color: #ffd700"],
  span[style*="color:#ffd700"],
  span[class="review-stars"],
  #root .review-rating span,
  .reviews-page .review-rating span,
  .reviews-page [style*="★"],
  .reviews-page span[style*="color: #ffd700"],
  .reviews-page .review-card span[style*="color: #ffd700"] {
    font-size: 22px !important;
  }
}

@media (max-width: 480px) {
  .review-stars,
  span[style*="color: #ffd700"],
  span[style*="color:#ffd700"],
  span[class="review-stars"],
  #root .review-rating span,
  .reviews-page .review-rating span,
  .reviews-page [style*="★"],
  .reviews-page span[style*="color: #ffd700"],
  .reviews-page .review-card span[style*="color: #ffd700"] {
    font-size: 20px !important;
  }
}

/* Extra små enheter (t.ex. mindre än 375px) */
@media (max-width: 375px) {
  .review-stars,
  span[style*="color: #ffd700"],
  span[style*="color:#ffd700"],
  span[class="review-stars"],
  #root .review-rating span,
  .reviews-page .review-rating span,
  .reviews-page [style*="★"],
  .reviews-page span[style*="color: #ffd700"],
  .reviews-page .review-card span[style*="color: #ffd700"] {
    font-size: 18px !important;
  }
}

/* Direkta selektorer för stjärnorna */
.review-stars,
span[style*="color: #ffd700"],
span[style*="color:#ffd700"],
span[class="review-stars"] {
  font-size: 10px !important;
  line-height: 1 !important;
}

/* Överskrid alla andra stjärnrelaterade stilar på recensioner-sidan */
#root .review-rating span,
.reviews-page .review-rating span,
.reviews-page [style*="★"],
.reviews-page span[style*="color: #ffd700"],
.reviews-page .review-card span[style*="color: #ffd700"] {
  font-size: 25px !important;
  line-height: 2 !important;
  letter-spacing: -1px !important;
}

/* Kraftfull regel för att garantera konsekvent namn-storlek på alla enheter */
.review-name,
.review-header .review-name,
div.review-card .review-name,
.reviews-page .review-name,
div.review-card div.review-header span.review-name,
.review-card .review-header .review-name,
body .reviews-page .review-name,
.reviews-page
  .reviews-container
  .reviews-grid
  .review-card
  div.review-header
  span.review-name {
  width: 100% !important;
  max-width: 100% !important;
  color: #333 !important;
  font-weight: bold !important;
  font-size: 20px !important; /* Större storlek på alla enheter */
  text-align: center !important;
  margin-bottom: 8px !important;
  white-space: normal !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  line-height: 1.2 !important;
  padding: 0 !important;
}

/* Tvinga samma storlek även i media queries */
@media (max-width: 768px) {
  .review-name,
  .review-header .review-name,
  div.review-card .review-name,
  .reviews-page .review-name,
  div.review-card div.review-header span.review-name {
    font-size: 20px !important; /* Samma storlek som desktop */
  }
}

@media (max-width: 480px) {
  .review-name,
  .review-header .review-name,
  div.review-card .review-name,
  .reviews-page .review-name,
  div.review-card div.review-header span.review-name {
    font-size: 20px !important; /* Samma storlek som desktop */
  }
}

@media (max-width: 375px) {
  .review-name,
  .review-header .review-name,
  div.review-card .review-name,
  .reviews-page .review-name,
  div.review-card div.review-header span.review-name {
    font-size: 20px !important; /* Samma storlek som desktop */
  }
}

/* Justera knappen för Visa mer/Visa mindre */
.view-full-review-button button {
  width: 100% !important;
  margin-top: 10px !important;
  margin-bottom: 5px !important;
  padding: 8px 15px !important;
  transition: background-color 0.3s ease !important;
}

/* Indikator för expanderad kommentar */
.view-full-review-button button span {
  display: inline-block !important;
  transform-origin: center !important;
  transition: transform 0.3s ease !important;
}

/* Rotera pilen när expanderad */
.review-card.expanded .view-full-review-button button span {
  transform: rotate(180deg) !important;
}

/* Kraftigare reset för expanderade recensionskort för att garantera isolation */
.review-card {
  display: flex !important;
  flex-direction: column !important;
  height: auto !important;
  transform: translateZ(0) !important;
  contain: layout style paint !important;
  isolation: isolate !important;
  position: relative !important;
  z-index: 1 !important;
  box-sizing: border-box !important;
}

/* Responsiv design för fullskärmsbilder på mobila enheter */
@media (max-width: 768px) {
  .fullscreen-image-container {
    max-width: 95%;
  }

  .fullscreen-image {
    max-height: 80vh;
  }

  .close-fullscreen-button {
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.6);
  }
}

@media (max-width: 480px) {
  .fullscreen-image-overlay {
    padding: 10px;
  }

  .fullscreen-image-container {
    max-width: 100%;
  }

  .fullscreen-image {
    max-height: 70vh;
  }
}

/* Lägg till stilar för det nya bildgalleriet */
.image-gallery-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
}

.image-gallery-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
}

.gallery-image-container {
  position: relative;
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
}

.gallery-image {
  cursor: zoom-in;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    filter 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center center;
  filter: brightness(1);
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.3);
  animation: fadeInSlide 0.6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  will-change: transform, opacity, filter;
}

.gallery-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  font-size: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10001;
  transition: background-color 0.2s ease;
}

.gallery-close-btn:hover {
  background-color: rgba(255, 0, 0, 0.9);
}

.gallery-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0.7;
  backdrop-filter: blur(4px);
}

.gallery-nav:hover {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
  transform: translateY(-50%) scale(1.1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.prev-btn {
  left: 20px;
}

.next-btn {
  right: 20px;
}

.gallery-counter {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 16px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  animation: fadeIn 0.3s ease-out;
}

/* Responsivt för mobila enheter */
@media (max-width: 768px) {
  .gallery-image-container {
    width: 95%;
    height: 90%;
  }

  .gallery-close-btn {
    width: 40px;
    height: 40px;
    font-size: 24px;
  }

  .gallery-nav {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }

  .prev-btn {
    left: 10px;
  }

  .next-btn {
    right: 10px;
  }

  .gallery-image {
    touch-action: none; /* Förhindra standard touch-beteenden under zoom */
  }

  .gallery-nav,
  .gallery-close-btn {
    width: 45px;
    height: 45px;
    opacity: 0.9;
  }
}

/* Lägg till hover-effekt på recensionsbilder */
.review-thumbnail:hover {
  transform: scale(1.03);
  transition: transform 0.3s ease;
}

/* Förbättra bläddringsknappar för bilder i recensioner */
.image-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  height: 36px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.image-nav:hover {
  opacity: 1;
}

.prev-image {
  left: 10px;
}

.next-image {
  right: 10px;
}

.image-counter {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  z-index: 2;
}

/* Öka bildstorleken och lägg till zoom-effekt i galleriet */
.gallery-image {
  cursor: zoom-in;
}

.gallery-image.zoomed {
  cursor: move;
  transition: none; /* Ta bort transition under drag för bättre prestanda */
  z-index: 10001;
  filter: brightness(1.1);
}

/* Förbättra touchvänlighet på mobila enheter */
@media (max-width: 480px) {
  .gallery-nav {
    width: 48px;
    height: 48px;
    font-size: 22px;
  }

  .gallery-close-btn {
    width: 48px;
    height: 48px;
  }
}

/* Lägg till animationer för bild-galleri */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.image-gallery-overlay {
  animation: fadeIn 0.3s ease forwards;
}

.gallery-image {
  animation: fadeIn 0.5s ease forwards;
}

/* Förbättra laddningsstatus */
.gallery-image-loading {
  color: white;
  font-size: 20px;
  text-align: center;
}

.review-card .view-more-button {
  display: inline-block;
  background-color: #333; /* Ändrat från grön till mörkgrå/svart */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  margin: 5px 0;
  width: auto;
  min-width: 80px;
  max-width: 100px;
  text-align: center;
  font-weight: 500;
  position: relative;
  z-index: 10;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.review-card .view-more-button:hover {
  background-color: #000; /* Ändrat från mörkgrön till helt svart vid hover */
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Bildspelsanimationer */
@keyframes fadeInSlide {
  from {
    opacity: 0;
    transform: translateX(50px) scale(0.95);
    filter: blur(5px);
  }
  to {
    opacity: 1;
    transform: translateX(0) scale(1);
    filter: blur(0);
  }
}

@keyframes fadeOutSlide {
  from {
    opacity: 1;
    transform: translateX(0) scale(1);
    filter: blur(0);
  }
  to {
    opacity: 0;
    transform: translateX(-50px) scale(0.95);
    filter: blur(5px);
  }
}

.gallery-image {
  cursor: zoom-in;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    filter 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center center;
  filter: brightness(1);
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.3);
  animation: fadeInSlide 0.6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  will-change: transform, opacity, filter;
}

.gallery-image.fade-out {
  animation: fadeOutSlide 0.6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.gallery-image-container {
  position: relative;
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
  perspective: 1500px;
}

/* Förbättrade navigeringsknappar med mjukare animation */
.gallery-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0.7;
  backdrop-filter: blur(4px);
}

.gallery-nav:hover {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
  transform: translateY(-50%) scale(1.1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.prev-btn {
  left: 20px;
}

.next-btn {
  right: 20px;
}

/* Förbättrad counter animation */
.gallery-counter {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 16px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  animation: fadeIn 0.3s ease-out;
}

/* Responsiva animationer */
@media (max-width: 768px) {
  @keyframes fadeInSlide {
    from {
      opacity: 0;
      transform: translateX(30px) scale(0.95);
      filter: blur(3px);
    }
    to {
      opacity: 1;
      transform: translateX(0) scale(1);
      filter: blur(0);
    }
  }

  @keyframes fadeOutSlide {
    from {
      opacity: 1;
      transform: translateX(0) scale(1);
      filter: blur(0);
    }
    to {
      opacity: 0;
      transform: translateX(-30px) scale(0.95);
      filter: blur(3px);
    }
  }

  .gallery-image {
    animation-duration: 0.5s;
  }
}
