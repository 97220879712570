.header {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  margin-bottom: 10px;
}

.logo-image {
  height: 100px;
  width: auto;
  filter: brightness(0) invert(1);
  display: block;
}

.nav {
  width: 100%;
  display: flex;
  justify-content: center;
}

.nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.nav-link {
  color: white !important;
  text-decoration: none !important;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 0;
  position: relative;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.nav-link:visited {
  color: white !important;
}

.nav-link:hover {
  color: white !important;
  opacity: 0.8;
}

.nav-link:active {
  color: white !important;
}

.nav-link.active {
  color: white !important;
}

@media (max-width: 768px) {
  .logo-image {
    height: 80px;
  }

  .nav-list {
    gap: 20px;
  }

  .nav-link {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .logo-image {
    height: 60px;
  }

  .nav-list {
    gap: 15px;
  }

  .nav-link {
    font-size: 14px;
  }
}
